

























































import Vue from 'vue';
import BaseTable from '../../costQuotaLibrary/components/baseTable.vue';
import { KindType, MaterialType } from '@/interface/materialMachineLibrary';
import { MaxNumberLimit } from '@/interface';

import { getNumber } from '@/utils';
import { getKindText as kindText } from '@/views/enterpriseLibrary';
const columns = [
  {
    title: '材机名称',
    key: 'materialName',
  },
  {
    title: '规格型号',
    key: 'specification',
  },
  {
    title: '类别',
    key: 'kind',
  },
  {
    title: '计量单位',
    key: 'unit',
  },
  {
    title: '定额价',
    key: 'dePrice',
  },
  {
    title: '企业价',
    key: 'marketPrice',
  },
  {
    title: '除税系数(%)',
    key: 'taxRemoval',
  },
  {
    title: '除税企业价',
    key: 'csMarketPrice',
  },
  // {
  //   title: '地区',
  //   key: 'area',
  // },
  // {
  //   title: '价格年月',
  //   key: 'priceDate',
  // },
];
const editFields = ['resQty', 'marketPrice'];
export default Vue.extend({
  name: 'materialMachineTable',
  props: {
    columns: {
      type: Array,
      default: () => {
        return Object.freeze(columns);
      },
    },
    isEdit: Boolean,
    rowKey: {
      type: String,
      default: 'sequenceNbr',
    },
    editFields: {
      type: Array,
      default: () => {
        return editFields;
      },
    },
  },
  data() {
    return {
      sortList: [KindType.R, KindType.C, KindType.J],
    };
  },
  computed: {
    isDisabledEdit() {
      return (record, key) => {
        // 编辑状态，并且是材料主材类型
        if (this.isEdit && this.isMaterial(record.kind)) {
          return false;
        }
        // 编辑状态，并且是人工机械类型，并且是消耗量
        if (
          this.isEdit &&
          [KindType.R, KindType.J].includes(record.kind) &&
          key === 'resQty'
        ) {
          return false;
        }
        return true;
      };
    },
  },
  methods: {
    editHandler(record, key, index) {
      const num = key === 'resQty' ? 6 : 2;
      record[key] = parseFloat(Number(record[key]).toFixed(num));
      this.checkFailField(record);
      this.$emit('updateData', { record, index });
    },
    kindSort(val) {
      let list: MaterialType[] = [];
      let otherList: MaterialType[] = [];
      const tableData: MaterialType[] = this.$attrs.tableData as any;
      tableData.forEach((item) => {
        if (item.kind === val) {
          list.push(item);
        } else {
          otherList.push(item);
        }
      });
      this.$emit('sortUpdate', [...list, ...otherList]);
    },
    /**
     * 检查字段是否有错误
     */
    checkFailField(record) {
      let errorNum = 0;
      editFields.forEach((item) => {
        let isCorrect = record[item] > 0;
        if (item === 'resQty') {
          isCorrect = record[item] < MaxNumberLimit.AMOUNT;
        }
        if (isCorrect) {
          if (record?.customCellClass) {
            delete record.customCellClass[item];
          }
        }
        if (!isCorrect) {
          if (!record?.customCellClass) {
            record.customCellClass = {};
          }
          record.customCellClass[item] = 'fail';
          errorNum++;
          if (item === 'resQty' && record[item] >= MaxNumberLimit.AMOUNT) {
            this.$message.warning(`不能大于${MaxNumberLimit.AMOUNT}`);
          }
        }
      });
      record.isError = !errorNum ? 0 : 1;
    },
    getKindText(kind) {
      return kindText(kind);
    },
    setClassName(record, key) {
      return record.customCellClass ? record.customCellClass[key] : '';
    },
    isMaterial(kind) {
      return [KindType.C, KindType.Z].includes(kind);
    },
  },
  components: {
    BaseTable,
  },
});
