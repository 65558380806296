var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"material"},[_c('baseTable',_vm._g(_vm._b({attrs:{"columns":_vm.columns,"rowKey":_vm.rowKey},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(item,key){return {key:key,fn:function(ref){
var record = ref.record;
var text = ref.text;
var index = ref.index;
return [_vm._t(key,null,{"record":record,"text":text,"index":index})]}}}),{key:"kindTitle",fn:function(){return [_c('a-dropdown',[_c('span',[_vm._v(" 类别 "),_c('a-icon',{attrs:{"type":"down"}})],1),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},_vm._l((_vm.sortList),function(item){return _c('a-menu-item',{key:item},[_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.kindSort(item)}}},[_vm._v(_vm._s(_vm.getKindText(item)))])])}),1)],1)]},proxy:true},{key:"kind",fn:function(ref){
var record = ref.record;
return [_vm._v(" "+_vm._s(_vm.getKindText(record.kind))+" ")]}},{key:"companyPrice",fn:function(ref){
var record = ref.record;
return [_vm._v(" "+_vm._s(record.companyPrice || '——')+" ")]}},{key:"csMarketPrice",fn:function(ref){
var record = ref.record;
return [_vm._v(" "+_vm._s(record.csMarketPrice || '——')+" ")]}},_vm._l((_vm.editFields),function(field){return {key:field,fn:function(ref){
var record = ref.record;
var index = ref.index;
return [_c('a-input',{key:field,class:['edit-input', _vm.setClassName(record, field)],attrs:{"type":"text","disabled":_vm.isDisabledEdit(record, field)},on:{"blur":function($event){return _vm.editHandler(record, field, index)}},model:{value:(record[field]),callback:function ($$v) {_vm.$set(record, field, $$v)},expression:"record[field]"}})]}}})],null,true)},'baseTable',_vm.$attrs,false),_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }